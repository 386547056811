import React, { useState } from "react";
import { Link, Stack, Text } from "basis";

import { logger } from "../../../core";
import { useCustomerAuth } from "../../../core/auth";
import { useThreatMetrix } from "../../../hooks";

import {
  Container,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Button,
} from "../../../components";
import {
  PaymentMethod,
  Scene,
  AccountErrors,
  completeConsent
} from "../../../layout/wallet";
import { useCustomerOrder } from "../../../layout/checkout";
import { retry } from "../../../utils/retry";
import styled from "styled-components";
import { COUNTRY } from "../../../core/constants";

const Checkout = () => {
  const {
    loading,
    error,
    order,
    setErrorMessage,
    params,
  } = useCustomerOrder();
  const tm = useThreatMetrix();

  const { logout } = useCustomerAuth();
  const [selectedAccount, setSelectedAccount] = useState();
  const [completingPayment, setCompletingPayment] = useState(false);

  const Wrapper = styled.div`
        margin: 24px 0;

        button {
          cursor: pointer;
          width: 100%;
        }
        `;
  const handlePaymentClick = async () => {
    if (loading || completingPayment) {
      return;
    }

    setCompletingPayment(true);

    try {
      const response = await retry({
        fn: () =>
          completeConsent(
            params.transactionId,
            selectedAccount?.accountID,
            tm.getSessionId()
          ),
        onRetryBegin: (numAttempt) =>
          numAttempt > 0 &&
          logger.info(`handlePaymentClick(): retry #${numAttempt}`, {
            params,
            paymentResponse: {
              selectedAccount,
            },
          }),
        attempts: 10,
        sleepMs: 200,
        sleepMsDelay: [800, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600, 2800],
      });
      window.location.replace(response.redirectUrl);
    } catch (err) {
      logger.error("handleConsentClick(): failed", { params, err });

      setErrorMessage(
        `We could not update your consent request. Ref # ${params.transactionId}`
      );
    }
  };
  if (loading) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <LoadingMessage message="Getting transaction details while you wait .." />
      </Scene>
    );
  }
  if (error?.message) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <ErrorMessage
          type={error?.message}
          additionalParams={{
            minimumAmount: order?.minimumAmount,
            currency: order?.currency,
            urlCancel: order?.urlCancel,
            transactionId: order?.transactionId,
          }}
        />
      </Scene>
    );
  }
  return (
    <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Flex>
            <Link href={order?.urlCancel} newTab={false} blue>
              &lt; Back to cart
            </Link>

            <Link href="#" onClick={logout} newTab={false}>
              Log out
            </Link>
          </Flex>

          <Text align="left" textStyle="heading4">
            Confirm Payment Method
          </Text>
        </Stack>
      </Container>


      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={600}>


          <PaymentMethod
            loading={completingPayment}
            accounts={order?.accounts}
            onChange={setSelectedAccount}
            label= "Using the following account:"
          />
          <AccountErrors
            errors={order?.accountErrors}
            selectedAccount={selectedAccount}
          />
          <Wrapper>
            <Text textStyle="legal" color="grey.t75" margin="8 0 4">By clicking ‘Use Payment Method’ you agree that:</Text>
            <ol style={{color:  '#414141', fontFamily:"Roboto, sans-serif",fontWeight:"300",fontSize: "14px", lineHeight:"24px", marginBottom:'16px', paddingLeft:'14px' }}>
              <li style={{marginBottom:'16px'}}>
                
                  Purchases made in connection to your Upgrade+ plan will be processed using the card ending in {selectedAccount?.cardLast4} (Detailed plan information is viewable in the Upgrade+ cart).
            
              </li>
              <li style={{marginBottom:'16px'}}>
                  You have read, understand
                  and accept our{" "}
                  <Link
                    href={`https://www.latitudefinancial.com.au/forms/`}
                    newTab
                  >
                    terms and conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    href={
                      order?.country === COUNTRY.NZ
                        ? `https://www.gemfinance.co.nz/privacy`
                        : `https://www.latitudefinancial.com.au/privacy`
                    }
                    newTab
                  >
                    privacy policy
                  </Link>
                  .

              </li>
            </ol>
            <Button
              variant="primary"
              showButton={Boolean(selectedAccount?.isAvailable)}
              loading={completingPayment}
              onClick={handlePaymentClick}
            >
              Use Payment Method
            </Button>
          </Wrapper>
        </Container>
      </Container>
    </Scene>
  );
};

export default Checkout;
